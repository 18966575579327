
.content {
    margin-block: 50px;
    margin-inline: auto;
    /* min-width: 700px; */
    /* min-width: 350px; */
    max-width: 500px;
    min-height:500px;
    max-height: 500px;
    border:1px solid #333;
    
}

.nav {
    /* min-width: 300px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 1px 5px #333;
}

.nav-logo {
    width: 60px;
    margin-left:20px;
}

.nav-items {
    list-style: none;
    display: flex;
}

.nav-items > li {
    padding: 10px;
}   

.body {
    padding: 5px;
}

.foot{
    position: absolute;
    bottom:30px;
    text-align: center;
    margin: auto;
}